import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HighlightOnClick from '../components/HighlightOnClick'
import BlogPosts from '../components/BlogPosts'

const IndexPage = () => {
  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: 'description',
            content:
              'Kamal Nasser is Senior Software Engineer at DigitalOcean. This is his website where he occasionally writes some blog posts',
          },
        ]}
      />

      <BlogPosts />

      <footer className="text-right text-sm text-gray-600">
        Try running: <HighlightOnClick className="cursor-pointer hover:text-red-600 font-medium">curl kamal.io</HighlightOnClick> ✨
      </footer>
    </Layout>
  )
}

export default IndexPage
