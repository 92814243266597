import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { parse as parseDate, format as formatDate } from 'date-fns'
import { getPostLinkDest, getPostType, postTypeClasses } from '../lib/util.js'

class PostListItem extends Component {
  getPostLink(post) {
    let linkClasses = 'py-1 px-1 hover:bg-gray-200 transition ease-in duration-75 rounded-sm bdb-clone'
    const { link: linkDest, isExternal } = getPostLinkDest(post)

    if (isExternal) {
      // external
      return (
        <a href={linkDest} className={linkClasses}>
          {post.title}
        </a>
      )
    }

    return (
      <Link to={linkDest} className={linkClasses}>
        {post.title}
      </Link>
    )
  }

  render() {
    const post = this.props.post
    const date = formatDate(parseDate(post.published_at), 'MMMM MM YYYY')
    const postType = getPostType(post)
    const type = {
      name: postType,
      className: postTypeClasses[postType]
    }

    const postLink = this.getPostLink(post)

    return (
      <div className="mb-5">
        <p className="text-blue-600 uppercase font-medium text-sm px-1">
          {date} <span className={type.className}>{'\u25AA'} {type.name}</span>
        </p>
        <p className="font-medium">
          {postLink}
        </p>
      </div>
    )
  }
}

const BlogPosts = () => (
  <StaticQuery
    query={graphql`
      query {
        allGhostPost(sort: { fields: [published_at], order: DESC }) {
          edges {
            node {
              title
              slug
              published_at
              custom_excerpt
              tags {
                name
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <article className="mb-20">
          {data.allGhostPost.edges.map(post => (
            <PostListItem post={post.node} key={post.node.slug} />
          ))}
        </article>
      )
    }}
  />
)

export default BlogPosts
