import React, { Component } from 'react'

class HighlightOnClick extends Component {
    handleClick(e) {
        const node = e.target
        let range

        if (document.selection) {
            range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
        } else if (window.getSelection) {
            range = document.createRange();
            range.selectNodeContents(node);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    }

    render() {
        return (
            <span className={this.props.className} onClick={this.handleClick}>{ this.props.children }</span>
        )
    }
}

export default HighlightOnClick
